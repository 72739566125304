<template>
  <div>
    <div>
      <headPage />
      <!-- 宅家悠享 -->
      <div class="box1" style="position: relative">
        <img
          style="display: block; width: 100%"
          src="../assets/image/entertainment/image3.png"
        />
        <div class="info">
          <div class="title1">宅家悠享</div>
          <div class="title1-1">极致视听盛宴</div>
        </div>
      </div>
      <!-- 六大特点 -->
      <div style="position: relative; background-color: black">
        <div class="box">
          <img
            style="
              display: block;
              width: 100%;
              transform: translate(-2px, -21px);
            "
            src="../assets/image/entertainment/image2.png"
          />
        </div>
        <div class="info2">
          <div class="info2-1">
            <img
              class="bottompic"
              style="display: block; width: 100%"
              src="../assets/image/entertainment/image6.jpg"
            />
            <div class="title2-1">4K高清画质</div>
            <div class="line"></div>
            <div class="title2-2">
              高亮度高对比度的画面，在观看昏暗场景时，画面更清晰。
            </div>
          </div>
          <div class="info2-1">
            <img
              class="bottompic"
              style="display: block; width: 100%"
              src="../assets/image/entertainment/image7.jpg"
            />
            <div class="title2-1">专业光学处理</div>
            <div class="line"></div>
            <div class="title2-2">
              主要体现在降低周围环境对观影的干涉。恰当的光学设计能够使视觉观感更立体，呈现完美的视听效果。
            </div>
          </div>
          <div class="info2-1">
            <img
              class="bottompic"
              style="display: block; width: 100%"
              src="../assets/image/entertainment/image8.jpg"
            />
            <div class="title2-1">隔音吸音处理</div>
            <div class="line"></div>
            <div class="title2-2">
              墙面加龙骨井塞满隔音棉，附上石膏板，以这种多层隔离井吸收的方式来达到最好的隔音效果；因声波会在密闭空间中来回反射，需提前做好声场检测，在反射面铺贴吸音材料。
            </div>
          </div>
          <div class="info2-1">
            <img
              class="bottompic"
              style="display: block; width: 100%"
              src="../assets/image/entertainment/image9.jpg"
            />
            <div class="title2-1">极致娱乐体验</div>
            <div class="line"></div>
            <div class="title2-2">
              影音室也可以作为娱乐室，开启KTV模式，充满张力的空间能赋予生活更多的趣味性与可变性。
            </div>
          </div>
          <div class="info2-1">
            <img
              class="bottompic"
              style="display: block; width: 100%"
              src="../assets/image/entertainment/image10.jpg"
            />
            <div class="title2-1">全景声音效</div>
            <div class="line"></div>
            <div class="title2-2">
              全方位震摅灵动的音效，市音清晰、饱满、细致且有深度，与此同时，能完美传递影片创作者的创作意图。
            </div>
          </div>
          <div class="info2-1">
            <img
              class="bottompic"
              style="display: block; width: 100%"
              src="../assets/image/entertainment/image11.jpg"
            />
            <div class="title2-1">个性化美学装饰</div>
            <div class="line"></div>
            <div class="title2-2">
              设计风格可多元化。根据整体装饰风格打造出绝佳家庭影院，让观影不再单调。
            </div>
          </div>
        </div>
      </div>
      <!-- 家庭影院 -->
      <div style="position: relative">
        <div class="box2">
          <img
            style="display: block; width: 100%"
            src="../assets/image/entertainment/image4.jpeg"
          />
          <div class="maskBox">
            <div class="info3" data-aos="fade-right">
              <div class="info3-1">客厅</div>
              <div class="info3-2">家庭影院</div>
              <div class="line3"></div>
              <div class="info3-3">
                客厅影院拥有较为开放的空间和开阔的视野，让影院与生活距离更近。可选用大尺寸平板电视和投影系统两种显示解决方案。
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 切换场景 -->
      <div class="box4" style="position: relative">
        <img
          style="display: block; width: 100%"
          src="../assets/image/entertainment/image5.png"
        />
        <div class="info4" data-aos="fade-right">
          <div class="info4-1">场景切换</div>
          <div class="info4-2">一键可达</div>
          <div class="info4-3">观影模式</div>
          <div class="info4-4">会客模式</div>
          <div class="info4-5">
            一键打开投影仪、功放、蓝光机、音响、投影幕布、吊架、空调、新风系统，关闭窗户、窗帘、灯光，轻松操作，家庭女主人也能看大片了。
          </div>
          <div class="info4-6">
            一键关闭投影仪、功放、蓝光机、音响、投影幕布、吊架、空调、新风系统，打开灯光、窗户、窗帘，避免了繁琐的操控。
          </div>
        </div>
      </div>
      <!-- 地下室 -->
      <div class="last" style="position: relative; overflow: hidden">
        <img
          style="display: block; width: 100%"
          src="../assets/image/entertainment/image1.png"
        />
        <div class="info5" data-aos="fade-left">
          <div class="info5-1">地下室</div>
          <div class="info5-2">家庭影院</div>
          <div class="line4"></div>
          <div class="info5-3">
            相对于电影院或客厅影院，地下室家庭影院更具私密性。MH享家从声学、光学等物理学角度规划设计，把电影院搬回家,在家就可以享受影院级别的待遇。
          </div>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.info2-1:hover {
  transform: scale(1.15);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.bottompic {
  position: absolute;
  z-index: -1;
}
.box4 {
  height: 616px;
}
.last {
  height: 610px;
}
.title1 {
  font-size: 58px;
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
}
.info {
  position: absolute;
  top: 225px;
  left: 592px;
}
.info2 {
  position: absolute;
  top: 0px;
  left: 63px;
  width: 1250px;
  left: 50%;
  height: 937px;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
}
.info2-1 {
  position: relative;
  height: 459px;
  width: 414px;
}
.title1-1 {
  font-family: "SourceHanSansCN-Blod";
  font-size: 57px;
  color: #d0ac32;
  margin-top: -10px;
}
.box {
  margin: 0 auto;
  width: 1250px;
  overflow: hidden;
  height: 937px;
  background-color: black;
}
.box2 {
  overflow: hidden;
  height: 607px;
}
.title2-1 {
  font-family: "SourceHanSansCN-Light";
  color: #fff;
  font-size: 30px;
  margin-top: 51px;
  text-align: left;
  margin-left: 46px;
}
.title2-2 {
  font-family: "SourceHanSansCN-Light";
  color: #d1cdca;
  font-size: 16px;
  width: 321px;
  text-align: left;
  margin-top: 21px;
  margin-left: 46px;
}
.line {
  height: 0.5px;
  width: 58px;
  background-color: #d0ac32;
  margin-top: 16px;
  margin-left: 47px;
}
// .maskBox {
//   width: 100%;
//   height: 608px;
//   position: absolute;
//   top: 0;
//   background-color: rgba(0, 0, 0, 0.7);
// }
.info3 {
  text-align: left;
  margin-top: -455px;
  margin-left: 135px;
}
.info3-1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 57px;
  color: #d0ac32;
}
.info5-1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 56px;
  color: #d0ac32;
  margin-top: 138px;
  margin-left: 759px;
}
.info4-1 {
  font-family: "SourceHanSansCN-Blod";
  font-size: 57px;
  color: #fff;
  margin-top: 114px;
  margin-left: 137px;
}
.info3-2 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 57px;
  color: #fff;
}
.info5-2 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 56px;
  color: #fff;
  margin-left: 759px;
  margin-top: -8px;
}
.info4-2 {
  font-family: "SourceHanSansCN-Blod";
  font-size: 57px;
  color: #d0ac32;
  margin-top: -74px;
  margin-left: 378px;
}
.info4-3 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 30px;
  color: #fff;
  margin-left: 135px;
  margin-top: 156px;
}
.info4-4 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 30px;
  color: #fff;
  margin-left: 562px;
  margin-top: -45px;
}
.info4-5 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #d1cdca;
  width: 302px;
  margin-left: 139px;
  margin-top: 17px;
}
.info4-6 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #d1cdca;
  width: 302px;
  margin-left: 564px;
  margin-top: -95px;
}
.line3 {
  height: 1px;
  width: 52px;
  background-color: #fff;
  margin-top: 51px;
  z-index: 999;
}
.line4 {
  height: 1px;
  width: 53px;
  background-color: #fff;
  margin-top: 22px;
  margin-left: 759px;
}
.info3-3 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #d1cdca;
  width: 565px;
  margin-top: 62px;
}
.info5-3 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #d1cdca;
  width: 496px;
  margin-top: 53px;
  margin-left: 760px;
  text-align: justify;
}
.info4 {
  position: absolute;
  width: 100%;
  height: 615px;
  top: 0;
  text-align: left;
}
.info5 {
  position: absolute;
  width: 100%;
  height: 615px;
  top: 0;
  text-align: left;
}
</style>